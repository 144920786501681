import { ContentBlockType } from '@tytapp/content';
import { HopeC2aBlock } from './hope-c2a-block';

export * from './hope-c2a-block';

const STANDARD: HopeC2aBlock = {
    type: 'hope-c2a',
    actionText: 'Support this Community',
    actionUrl: '/join/membership',
    message: 'We can only create hope with your support.',
    backgroundImageUrl: '/assets/operation-hope/support-c2a-bg.png'
}

export const HOPE_C2A_BLOCK_TYPE: ContentBlockType<HopeC2aBlock> = {
    id: 'hope-c2a',
    label: 'Hope C2A',
    icon: 'handshake',
    template: STANDARD,
    preview: STANDARD,
    deferredEditorComponent: () => import('./hope-c2a-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./hope-c2a-block.view').then(m => m.ViewComponent)
}
