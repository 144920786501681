<div class="discussion-container">
  @if (state === 'loading') {
    @for (n of [].constructor(expectedCount); track n) {
      <a class="discussion-item placeholder">
        <div class="discussion-details">
          <div class="image">
            <div role="img"></div>
          </div>
          <div class="discussion-content">
            <ul>
              <li>
                <span class="placeholder"></span>
              </li>
            </ul>
            <div class="attribution">
              <span class="placeholder"></span>
              <span class="placeholder" style="width: 8em"></span>
            </div>
            <div class="count">
              <div class="number">
                <span class="placeholder" style="width: 2em"></span>
              </div>
              <div>
                <mat-icon>comment</mat-icon>
              </div>
              Replies
            </div>
          </div>
        </div>
      </a>
    }
  }
  @if (state === 'ready') {
    @for (discussion of discussions; track discussion) {
      <a class="discussion-item" href="{{environment.urls.forums}}/t/{{discussion.slug}}" target="_blank"
        >
        <div class="discussion-details">
          <div class="image">
            <div role="img" [responsiveBackgroundImage]="discussion.image_url" aria-hidden="true"></div>
          </div>
          <div class="discussion-content">
            <ul>
              <li>
                {{discussion.title}}
              </li>
            </ul>
            <div class="attribution">
              Posted by <span class="username">&#64;{{discussion.last_poster_username}}</span>
              <tyt-timestamp [value]="discussion.last_posted_at"></tyt-timestamp>
            </div>
            <div class="count">
              <div class="number">{{discussion.reply_count}}</div>
              <div>
                <mat-icon>comment</mat-icon>
              </div>
              Replies
            </div>
          </div>
        </div>
      </a>
    }
  }
</div>