import { ContentBlockType } from '@tytapp/content';
import { FeaturedMembersBlock } from './featured-members-block';

export * from './featured-members-block';

export const FEATURED_MEMBERS_BLOCK_TYPE: ContentBlockType<FeaturedMembersBlock> = {
    id: 'featured-members',
    label: 'TYT Heroes',
    icon: 'favorite',
    editable: false,
    template: {
    },
    preview: {
    },
    deferredEditorComponent: () => import('./featured-members-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./featured-members-block.view').then(m => m.ViewComponent)
}
