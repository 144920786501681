@if (state === 'loading') {
  @for (n of [].constructor(count); track n) {
    <a class="member placeholder" aria-hidden="true">
      <div class="avatar"></div>
      <div class="info">
        <div class="title"><span class="placeholder"></span></div>
        <div class="username"><span class="placeholder"></span></div>
        <!-- TODO: socials? -->
        <div class="member-badges">
          <div class="member-badge">
            <mat-icon>person</mat-icon>
            <div class="info">
              <div class="name"><span class="placeholder"></span></div>
              <div class="detail"><span class="placeholder"></span></div>
            </div>
          </div>
          <div class="member-badge">
            <img src="/assets/community/plus-member.svg" aria-hidden="true">
            <div class="info">
              <div class="name"><span class="placeholder"></span></div>
              <div class="detail">
                <span class="placeholder"></span>
              </div>
            </div>
          </div>
          <div class="member-badge">
            <img src="/assets/community/tyt-army.svg" aria-hidden="true">
            <div class="info">
              <div class="name"><span class="placeholder"></span></div>
              <div class="detail"><span class="placeholder"></span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="about"><span class="placeholder"></span></div>
      </div>
    </a>
  }
}
@if (state === 'ready') {
  @for (member of members; track member.user.id) {
    <a routerLink="/@{{member.user.username}}" class="member">
      <div class="avatar" [responsiveBackgroundImage]="member.user.avatar"></div>
      <div class="info">
        <div class="title">Community Member</div>
        <div class="username">{{ member.user.username }}</div>
        <!-- TODO: socials? -->
        <div class="badges">
          <div class="badge">
            <mat-icon>person</mat-icon>
            <div class="info">
              <div class="name" aria-hidden="true">Community</div>
              <div class="detail" attr.aria-label="Community member since {{member.user.profile.signed_up_at | date: 'shortDate'}}">
                {{member.user.profile.signed_up_at | date: 'shortDate'}}
              </div>
            </div>
          </div>
          @if (member.user.profile.member_since) {
            <div class="badge">
              <img src="/assets/community/plus-member.svg" aria-hidden="true">
              <div class="info">
                <div class="name" aria-hidden="true">Plus</div>
                @if (member.user.profile.member_number) {
                  <div
                    class="detail"
                    attr.aria-label="TYT Member number {{member.user.profile.member_number}}"
                    >
                  #{{member.user.profile.member_number}}</div>
                }
              </div>
            </div>
          }
          @if (member.user.profile.is_tyt_army) {
            <div class="badge">
              <img src="/assets/community/tyt-army.svg" aria-hidden="true">
              <div class="info">
                <div class="name" aria-hidden="true">Volunteer</div>
                <div class="detail" aria-label="Volunteers with TYT Army">TYT ARMY</div>
              </div>
            </div>
          }
        </div>
      </div>
      <div class="wrapper">
        <div class="about" [innerHTML]="member.user.tyt_highlight | markdownToHtml | trustHtml"></div>
      </div>
    </a>
  }
}
