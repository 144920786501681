import { Injectable, Type } from '@angular/core';
import { Icon, Link } from '@tytapp/common';

export interface FollowButton {
    type: string;
    id: string;
}

export interface HomeCard {
    type?: string;
    cardId?: string;
    tags?: string[];
    primary?: boolean;
    placeholder?: boolean;
    title?: string;
}

export interface HomeCardComponentType {
    card: HomeCard;
    title?: string;
    icon?: Icon;
    more?: Link;
    followButton?: FollowButton;
    hidden?: boolean;
    classes?: string[];
    fullBleed?: boolean;
}

const HOME_CARD_ID = Symbol('HOME_CARD_ID');

export function ProvidesHomeCard(id: string) {
    return target => {
        target[HOME_CARD_ID] = id;
        return target;
    }
}

function getHomeCardID(target: any) {
    if (!target[HOME_CARD_ID])
        throw new Error(`Missing @ProvidesHomeCard() on component ${target.name}`);
    return target[HOME_CARD_ID];
}

export interface HomeCardComponentTypeRegistration<T = any> {
    id?: string;
    devToolsLabel: string;
    type: Type<T>;
    parameters?: Partial<T> & Omit<Partial<HomeCardComponentType>, 'card'>;
    initializer?: (component: T) => void;
}

@Injectable()
export class HomeCardsService {
    private map = new Map<string, HomeCardComponentTypeRegistration>();

    register<T>(registration: HomeCardComponentTypeRegistration<T>) {
        registration.id ??= getHomeCardID(registration.type);
        this.map.set(registration.id, registration);
    }

    getType(id: string) {
        return this.map.get(id) ?? this.map.get('*');
    }

    getTypes() {
        return Array.from(this.map.entries()).map(([k, v]) => v);
    }
}