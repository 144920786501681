import { ContentBlockType } from '@tytapp/content';
import { ForumPostsBlock } from './forum-posts-block';

export * from './forum-posts-block';

export const FORUM_POSTS_BLOCK_TYPE: ContentBlockType<ForumPostsBlock> = {
    id: 'forum-posts',
    label: 'Forum Posts',
    icon: 'forum',
    template: {
        category: 'main',
        sort: 'latest'
    },
    preview: {
        category: 'main',
        sort: 'latest'
    },
    deferredEditorComponent: () => import('./forum-posts-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./forum-posts-block.view').then(m => m.ViewComponent)
}
