import { NgModule } from '@angular/core';
import { ContentBlocksService } from '@tytapp/content';
import { FEATURED_MEMBERS_BLOCK_TYPE } from './featured-members-block';
import { HOPE_C2A_BLOCK_TYPE } from './hope-c2a-block';
import { FORUM_POSTS_BLOCK_TYPE } from './forum-posts-block';
import { FeaturedMembersComponent } from './featured-members/featured-members.component';
import { CommonModule } from '@angular/common';
import { TYTCommonModule } from '@tytapp/common';
import { CommonUiModule } from '@tytapp/common-ui';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ChatModule } from '@tytapp/chat';
import { ForumPostsComponent } from './forum-posts/forum-posts.component';

const COMPONENTS = [
    FeaturedMembersComponent,
    ForumPostsComponent
];

@NgModule({
    imports: [
        CommonModule,
        TYTCommonModule,
        RouterModule,
        CommonUiModule,
        MatIconModule,
        ChatModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class CommunityModule {
    constructor() {
        ContentBlocksService.register(FEATURED_MEMBERS_BLOCK_TYPE);
        ContentBlocksService.register(HOPE_C2A_BLOCK_TYPE);
        ContentBlocksService.register(FORUM_POSTS_BLOCK_TYPE);
    }
}