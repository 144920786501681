import { Component, inject, Input } from '@angular/core';
import { ApiAbbreviatedForumTopic, CommunityApi } from '@tytapp/api';
import { LoggerService } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'tyt-forum-posts',
    templateUrl: './forum-posts.component.html',
    styleUrls: ['./forum-posts.component.scss']
})
export class ForumPostsComponent {
    private logger = inject(LoggerService);
    private forumApi = inject(CommunityApi);

    async ngOnInit() {
        this.fetch();
    }

    state: 'loading' | 'ready' = 'loading';
    expectedCount = 4;
    discussions: ApiAbbreviatedForumTopic[];

    get hidden() { return this.discussions?.length === 0 };

    private _sort: 'latest' | 'top' = 'latest';
    @Input() get sort() { return this._sort; }
    set sort(value) {
        if (this._sort === value)
            return;
        this._sort = value;
        this.fetchDeferred();
    }

    private _category = 'main';
    @Input() get category() { return this._category; }
    set category(value) {
        if (this._category === value)
            return;
        this._category = value;
        this.fetchDeferred();
    }

    get environment() {
        return environment;
    }

    private _count = 4;
    private updateTimeout;

    private fetchDeferred() {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => this.fetch(), 10);
    }

    @Input() get count() { return this._count; }
    set count(value) {
        if (this._count === value)
            return;
        this._count = value;
        this.fetchDeferred();
    }

    private _page = 1;
    @Input() get page() { return this._page; }
    set page(value) {
        if (this._page === value)
            return;
        this._page = value;
        this.fetchDeferred();
    }

    private async fetch() {
        try {
            this.discussions = await firstValueFrom(this.forumApi.fetchForumTopics(
                this.category, this.count, this.page, this.sort
            ));
        } catch (e) {
            this.logger.error(`Failed to fetch forum posts for forum '${this.category}':`);
            this.logger.error(e);
            this.discussions = [];
        }

        this.state = 'ready';
    }
}