import { Component, inject, Input } from '@angular/core';
import { ApiHomeRow, ApiUser, ApiUserSnippet, CommunityApi } from '@tytapp/api';
import { HomeCardComponentType, ProvidesHomeCard } from '@tytapp/app/pages/home-page/home-cards.service';
import { AppConfig, Icon, LoggerService } from '@tytapp/common';
import { firstValueFrom } from 'rxjs';

interface SocialProfile {
    image: string;
    name: string;
    url: string;
}

interface FeaturedMember {
    user: ApiUserSnippet;
    socials: SocialProfile[];
}

@ProvidesHomeCard('featured_members')
@Component({
    selector: 'tyt-featured-members',
    templateUrl: './featured-members.component.html',
    styleUrls: ['./featured-members.component.scss']
})
export class FeaturedMembersComponent implements HomeCardComponentType {
    private logger = inject(LoggerService);
    public appConfig = inject(AppConfig);
    communityApi = inject(CommunityApi);
    state: 'loading' | 'ready' = 'loading';

    @Input() count = 1;

    members: FeaturedMember[];
    card: ApiHomeRow;

    get title() {
        return this.appConfig.appStatus?.settings?.tyt_heroes_title ?? 'TYT Heroes';
    }

    icon: Icon = { type: 'material', text: 'person' };

    get hidden() { return this.members?.length === 0; }

    async ngOnInit() {
        let users: ApiUser[];
        try {
            users = await firstValueFrom(this.communityApi.getFeaturedCommunityMembers(this.count));
        } catch (e) {
            this.logger.error(`Failed to fetch featured members:`);
            this.logger.error(e);
            users = [];
        }

        this.members = users.map(user => ({ user, socials: this.getSocialsForUser(user) }))
        this.state = 'ready';
    }

    private getSocialsForUser(user: ApiUserSnippet) {
        let socials: SocialProfile[] = [];

        if (user.profile.twitter_profile) {
            socials.push({ name: 'Twitter', image: 'assets/community/twitter-logo.svg', url: `https://twitter.com/${user.profile.twitter_profile}` });
        }

        if (user.profile.facebook_profile) {
            socials.push({ name: 'Facebook', image: 'assets/community/facebook-logo.svg', url: `https://facebook.com/${user.profile.facebook_profile}` });
        }

        if (user.profile.instagram_profile) {
            socials.push({ name: 'Instagram', image: 'assets/community/instagram-logo.svg', url: `https://www.instagram.com/${user.profile.instagram_profile}` });
        }

        if (user.profile.linkedin_url) {
            socials.push({ name: 'LinkedIn', image: 'assets/community/linkedin-logo.svg', url: `https://${user.profile.linkedin_url}` });
        }

        return socials;
    }
}
